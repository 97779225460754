import FullFiledIcon from "../../../../static/images/reffilPageIcons/FullFiledIcon.svg"
import s from "./fullfiledAnswer.module.scss"
const FullfiledAnswer = () => {
  return (
      <div className={s.fullfiledAnswerBlock}>
          <div className={s.iconContainer}>
              <img src={FullFiledIcon} alt="RejectIcon"/>
              <span className={s.text}>Успешно</span>
          </div>
          <span className={s.text}>Заявка успешно создана.</span>
      </div>
  )
}

export default FullfiledAnswer
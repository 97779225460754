import s from "./aboutUs.module.scss"
import logo from "../../../static/images/LogoIcon.svg"
import InfiniteLoop from "../../../Components/InfiniteLoop/InfiniteLoop";
const AboutUsBlock = () => {
  return(
      <div className={s.aboutUsBlock}>
          <div className={s.wrapperBlock}>
              <h2 className={s.titleHero}>О нас</h2>
              <div className={s.descriptionContainer}>

                  <img src={logo} alt="logo"/>

                  <span className={s.descriptionText}>
                      Крупнейшее digital агентство на территории Центральной Азии.
                      Начиная с 2016 года TDS Media помогло более 200 рекламодателям
                      повысить узнаваемость своих брендов и увеличить продажи в Интернете.
                  </span>
              </div>
              <span className={s.footerText}>Нам доверяют</span>
              <div className={'logos-container'}>
                  <InfiniteLoop />
              </div>
          </div>
      </div>
  )
}

export default AboutUsBlock
import React, {useContext, useEffect, useState} from "react";
import Footer from "../../Components/Footer/Footer";
import HeaderRefill from "./HeaderRefill/HeaderRefill";
import HomeBlockRefill from "./HomeBLockRefill/HomeBlockRefill";
import RequirementBlock from "./RequirementBlock/RequirementBlock";
import CalculatorBlock from "./CalculatorBlock/CalculatorBlock";
import FAQBlock from "./FAQBlock/FAQBlock";
import AboutUsBlock from "./AboutUs/AboutUsBlock";
import ContactBlock from "./ContactBlock/ContactBlock";
import CustomModal from "../../Components/Modal";
import RefillModalContext from "../../context/RefillModalContext";
import {generalFormDataStepTwo} from "./constRefill/stepTwoModalConst";
import {getInitialFormDataStepOne} from "./constRefill/stepOneModalConst";
import {get_industry} from "../../api/RefillApi";
import getTemplateModal from "./innerModal/getTemplateModal/getTemplateModal";
import Loading from "./innerModal/Loading/Loading";
import FullfiledAnswer from "./innerModal/FulfiledAnswer/FullfiledAnswer";
import RejectAnswer from "./innerModal/RejectAnswer/RejectAnswer";
import CreateAccStepThree from "./innerModal/CreateAccStepThree/CreateAccStepThree";

export const RefillPage = () => {
    const {
        setOpenModal,openModal, setMode,
        setTypeSystem, setTypeOrganization,
        setStepOneFormData, setStepTwoFormData,
        setIndustriesData
    } = useContext(RefillModalContext)

    useEffect(() => {
        (async ()=>{
            const industries_data = await get_industry()
            setIndustriesData(industries_data.data)
        })()
    }, []);
    const handleCloseModal = () => {
        setOpenModal(false)
        setMode("step1")
        setTypeSystem(undefined)
        setTypeOrganization(undefined)
        setStepOneFormData(getInitialFormDataStepOne())
        setStepTwoFormData(generalFormDataStepTwo)
    }
    // mode: step1 | step2 | step3 | loading | fullfiled | reject | refill
    const TemplateModal = getTemplateModal()

  return(
      <div style={{minHeight: "100vh", display: "flex", flexDirection :"column", justifyContent: "center"}}>
          <HeaderRefill />
          <main style={{width: "100%", flex: "1 1 auto"}}>
              <HomeBlockRefill/>
              <RequirementBlock/>
              <CalculatorBlock/>
              <FAQBlock/>
              <AboutUsBlock/>
              <ContactBlock/>
              <CustomModal
                  top={55}
                  width={704}
                  open={openModal}
                  setOpen={setOpenModal}
                  handleClose={() => handleCloseModal()}
              >
                  <TemplateModal/>

              </CustomModal>
          </main>
          <Footer/>
      </div>
  )
}
import GoogleImg from "../../../static/images/companies/refillCompains/Google";
import GoogleColumnImg from "../../../static/images/companies/refillCompains/GoogleColumn";
import YandexImg from "../../../static/images/companies/refillCompains/Yandex";
import YandexColumnImg from "../../../static/images/companies/refillCompains/YandexColumn";
import TikTokImg from "../../../static/images/companies/refillCompains/TikTok";
import TikTokColumnImg from "../../../static/images/companies/refillCompains/TikTokColumn";
import MetaImg from "../../../static/images/companies/refillCompains/Meta";
import MetaColumnImg from "../../../static/images/companies/refillCompains/MetaColumn";
import {Yandex} from "./yandexInfo";
import {Google} from "./googleInfo";
import {TikTok} from "./tikTokInfo";
import {Meta} from "./metaInfo";

const mobileMedia = window.innerWidth <= 600
export const partners = [
    {img: mobileMedia ? GoogleColumnImg : GoogleImg, description: "Купон +300$", title: "Google"},
    {img: mobileMedia ? YandexColumnImg : YandexImg, description: "Cashback 10%", title: "Yandex"},
    {img: mobileMedia ? TikTokColumnImg : TikTokImg, description: "Cashback 5%", title: "TikTok"},
    {img: mobileMedia ? MetaColumnImg : MetaImg, description: null, title: "Meta"},
]

export const partnersSwiper = [
    {img: GoogleImg, description: "Купон +300$", title: "Google"},
    {img: YandexImg, description: "Cashback 10%", title: "Yandex"},
    {img: TikTokImg, description: "Cashback 5%", title: "TikTok"},
    {img: MetaImg, description: null, title: "Meta"},
]

export const partnersInfo = {
    Yandex,
    Google,
    TikTok,
    Meta
}

export const faqInfo = [
    {
        title: "Я подал заявку на получение аккаунта, когда на мою почту придет приглашение в Аккаунт?",
        description: "Приглашение приходит на указанную вами почту при заполнении заявки в течении 10 минут. Если вы его не получили обратитесь в нашу Тех. поддержку."
    },
    {
        title: "Как мне войти в мой аккаунт?",
        description: "Для входа в аккаунт пройдите по ссылке, логин и пароль был отправлен вам на почту указанную при заполнении заявки на получение аккаунта."
    },
    {
        title: "Как мне пополнить мой аккаунт и получить бонусы?",
        description: "Для пополнения вашего аккаунта Вам необходимо пройти на сайт нашего партнера tds.media в раздел Пополнить Аккаунт, ввести логин вашего аккаунта и произвести оплату."
    },
    {
        title: "Как быстро происходит зачисление денежных средств и бонусов на аккаунт?",
        description: "При пополнении банковской картой - зачисление денежных средств и бонусов на аккаунт занимает не более 5 минут 24/7. При пополнении через оплату по банковским реквизитам до 60 минут, после поступления денежных средств на расчетный счет нашего партнера."
    },
    {
        title: "Если у меня уже есть свой аккаунт Яндекс Директ, я могу через Вас пополнять его с Бонусами?",
        description: "Нет, к сожалению это невозможно."
    },
    {
        title: "Как мне создать рекламную кампанию?",
        description: "Мы рекомендуем Вам изучить обучающий материал по созданию и настройке рекламы на официальном Youtube канале Яндекса."
    },
]

export const formatNumber = (num) => {
    num = num.replace(/[^\d.,]/g, '').replace(',', '.')
    const [integerPart, decimalPart] = num.split('.')
    // Форматируем число с пробелами 100 000
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return decimalPart !== undefined ? `${formattedIntegerPart}.${decimalPart.length > 2 ? decimalPart.slice(0, 2) : decimalPart}` : formattedIntegerPart
};
import React, {useCallback, useState} from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import {Swiper, SwiperSlide} from "swiper/react";
import {nanoid} from "nanoid";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import s from "./index.module.scss"

const SwiperCompanyRefill = ({setCurrentPartner, data}) => {
    const [swiperRef, setSwiperRef] = useState();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const handleSlideChange = useCallback((swiper) => {

        setCurrentPartner(data[swiper.activeIndex].title);
    }, []);

    const generalProps = {
        onSwiper: setSwiperRef,
        grabCursor: true,
        loop: false,
        // className: 'swiper-feedback',
    };

    const mobileProps = {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 32,
    };

    return (
        <div className={s.swiperButtons}>
            <button onClick={handlePrevious} className={s.btnR}>
                <ArrowBackIosIcon sx={{height: 24}} />
            </button>
            <Swiper {...generalProps} {...mobileProps} onSlideChange={handleSlideChange} navigation>
                {data.map(({img, description}, index) => (
                    <SwiperSlide key={index}>
                        <div className={s.card} >
                            {
                                img({textColor: "#000"})
                            }
                            <span>
                                {
                                    description
                                }
                            </span>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <button onClick={handleNext} className={s.btnL}>
                <ArrowForwardIosIcon sx={{height: 24}} />
            </button>
        </div>
    );
}

export default SwiperCompanyRefill
const ExpandMinus = () => {
  return(
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="#FDBA2F"/>
          <path d="M8 12H12H16" stroke="#3E455C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

  )
}

export default ExpandMinus
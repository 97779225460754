const sumRound = (sum) => {
    return   Math.round(sum * 100) / 100
}

export const calculate = ({type_calculation, type_input, main_input, x, y}) => {
    //Если клиент начнет с X и хочет получить Y с кэшбеком в 5% то формула будет X / 1.12 * 1.05 = Y и
    // если он начнет с Y и хочет посмотреть сколько будет X то от обратного Y / 1.05 * 1.12 = X.

    //Если клиент начнет с X и хочет получить Y с комиссией в 5% то формула будет X / 1.12 / 1.05 = Y и
    //если он начнет с Y и хочет посмотреть сколько будет X то от обратного Y * 1.05 * 1.12 = X
  switch (type_input) {
      case `${main_input}` : {
          switch (type_calculation) {
              case "cashback" : {
                  // (x / 1.12) * y | cashback
                  const sum_refill = `${sumRound((x / 1.12) * y)}`
                  const sum_with_NDS = `${sumRound(x - (x / 1.12))}`
                  const sum_cashback_commission = `${sumRound(sum_refill - (x / 1.12))}`
                  return {sum_refill, sum_with_NDS, sum_cashback_commission}
              }
              case "commission" : {
                  // (x / 1.12) / y | commission
                  const sum_refill = `${sumRound((x / 1.12) / y)}`
                  const sum_with_NDS = `${sumRound(x - (x / 1.12))}`
                  const sum_cashback_commission = `${sumRound((x / 1.12) - sum_refill)}`
                  return {sum_refill, sum_with_NDS, sum_cashback_commission}
              }
              default: break
          }


          break
      }
      case "sum_refill" : {
          switch (type_calculation) {
              case "cashback" : {
                  // (x / y) * 1.12 | cashback
                  const bill_sum = `${sumRound((x / y) * 1.12)}`
                  const sum_with_NDS = `${sumRound(bill_sum - (bill_sum / 1.12))}`
                  const sum_cashback_commission = `${sumRound(x - (bill_sum / 1.12))}`
                  return {[main_input]:bill_sum, sum_with_NDS, sum_cashback_commission}
              }
              case "commission" : {
                  // (x * y) * 1.12 | commission
                  const bill_sum = `${sumRound((x * y) * 1.12)}`
                  const sum_with_NDS = `${sumRound(bill_sum - (bill_sum / 1.12))}`
                  const sum_cashback_commission = `${sumRound((bill_sum / 1.12) - x)}`
                  return {[main_input]:bill_sum, sum_with_NDS, sum_cashback_commission}
              }
              default: break
          }
          break
      }
      default:break
  }
}
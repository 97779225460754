import s from "./requirementBlock.module.scss"
import {useState} from "react";
import {partners, partnersInfo, partnersSwiper} from "../constRefill/const";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandPlus from "../../../static/images/reffilPageIcons/ExpandPlus";
import ExpandMinus from "../../../static/images/reffilPageIcons/ExpandMinus";
import SwiperCompanyRefill from "../SwiperCompanyRefill/SwiperCompanyRefill";



const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    padding: "16px 0 0 0",
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    flexDirection: 'row-reverse',

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },

}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: "16px 0 0 0",
    display: "flex",
    flexWrap: "wrap",
    gap: "16px"
}));

const RequirementBlock = () => {
    const [currentPartner, setCurrentPartner] = useState( "Google")

    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleOnClick = (title) => {
        setCurrentPartner(title)
    }


  return (
      <section className={s.requirementBlock} >
          <div className={s.wrapperBlock} >

              <h2 className={s.titleHero}>Условия пополнения</h2>

              <div className={s.infoContainer}>

                  <div className={s.partnersContainer}>
                      {
                          partners.map((partnerItem, index) => {
                              const {img, title,description} = partnerItem
                              return (
                                  <div
                                      key={index}
                                      className={`${s.partnerCard} ${currentPartner === title ? s.activePartner : ""}`}
                                      onClick={() => handleOnClick(title)}
                                  >
                                      {
                                          img({textColor: "#000"})
                                      }
                                      {
                                          title && <span className={s.partnerTitle}>{description}</span>
                                      }
                                  </div>
                              )
                          })
                      }
                  </div>

                  <SwiperCompanyRefill setCurrentPartner={setCurrentPartner} data={partnersSwiper}/>

                  <div className={s.descriptionsContainer}>

                      {
                           Object.keys(partnersInfo[currentPartner]).map( (titleAccordion, index) => {
                               return(
                                   <Accordion
                                       expanded={expanded === `${titleAccordion}-${index}`}
                                       onChange={handleChange(`${titleAccordion}-${index}`)}
                                   >
                                       <AccordionSummary
                                           aria-controls="panel1d-content"
                                           id="panel1d-header"
                                           expandIcon={expanded === `${titleAccordion}-${index}` ? <ExpandMinus/> : <ExpandPlus/>  }
                                       >
                                           <span className={s.titleAccordion}>
                                               {
                                                   titleAccordion
                                               }
                                           </span>
                                       </AccordionSummary>
                                       <AccordionDetails >
                                           {
                                               partnersInfo[currentPartner][titleAccordion].map((el, index) => {
                                                   return <div key={index} className={s.descriptionContainer} dangerouslySetInnerHTML={{__html: el}} />
                                               })
                                           }
                                       </AccordionDetails>
                                   </Accordion>
                               )
                           })
                      }

                  </div>

              </div>

          </div>
      </section>
  )
}

export default RequirementBlock
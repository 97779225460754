
// ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$ email
// ^(?!https?:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$ домен
// ^[A-Za-zА-Яа-яЁё]+(?:[-\s][A-Za-zА-Яа-яЁё]+)*$ имя
// ^[A-Za-zА-Яа-яЁё]+(?:[-\s][A-Za-zА-Яа-яЁё]+)*$ фамилия
// ^.+$ проверка на пустоту
// const isDisableBtn = !(trustCheck[0].checked && trustCheck[1].checked)
//^(?:[5-9]\d{4}([,\.]\d{1,2})?)$ свыше 50.000
//^(?:(?:[5-9]\d{4}([,\.]\d{1,2})?)|(:?[1-4]\d{5}([,\.]\d{1,2})?)|500000)$ от 50.000 до 500.000

export const validationScheme = {
    first_name: {
        regex: "^[A-Za-zА-Яа-яЁё]+(?:[-\\s][A-Za-zА-Яа-яЁё]+)*$",
        errorMessage: "Введите имя, используя только буквы, пробелы или дефисы."
    },
    last_name:{
        regex: "^[A-Za-zА-Яа-яЁё]+(?:[-\\s][A-Za-zА-Яа-яЁё]+)*$",
        errorMessage: "Введите фамилию, используя только буквы, пробелы или дефисы."
    },
    email: {
        regex: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        errorMessage: "Введите корректный адрес электронной почты."
    },
    placement: {
        regex: "^(?!https?:\\/\\/)[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        errorMessage: "Введите корректный домен без протокола (http/https)."
    },
    phone_number: {
        regex: "^\\+?[1-9]\\d{1,14}(?:[-\\s]?\\d{1,4}){0,4}$",
        errorMessage: "Введите корректный номер телефона."
    },
    industry: {
        regex: "^(?!null).+$",
        errorMessage: "Выберите отрасль"
    },
    sub_industry: {
        regex: "^(?!null).+$",
        errorMessage: "Выберите подотрасль"
    },
    promocode: {
        regex: "^.{0,50}$",
        errorMessage: "Поле не должно превышать 50 символов."
    },
    organization_type:{
        regex: "^(?!null).+$",
        errorMessage: "Поле не должно быть пустым."
    },
    ad_system: {
        regex: "^(?!null).+$",
        errorMessage: "Поле не должно быть пустым."
    },
    system_name: {
        regex: "^(?!null).+$",
        errorMessage: "Поле не должно быть пустым."
    },
    name: {
        regex: "^.{1,50}$",
        errorMessage: "Поле не должно быть пустым и не должено превышать 50 символов."
    },
    bin_number: {
        regex: "^\\d{12}$",
        errorMessage: "БИН должен содержать ровно 12 цифр."
    },
    company_type: {
        regex: "^.+$",
        errorMessage: "Выберите тип компании."
    },
    reference: {
        // regex: "^(image\\/(jpeg|png|jpg)|application\\/(pdf|msword|vnd\\.openxmlformats-officedocument\\.wordprocessingml\\.document))$",
        // regex: "^\\{.*\\}$ ",
        regex: "^(?!undefined$).+$",
        // errorMessage: "Недопустимый тип файла. Допустимы: JPG, JPEG, PNG, PDF, DOC, DOCX."
        errorMessage: "Поле не должно быть пустым."
    },
    first_pay_sum: {
        regex: "^(?:(?:[5-9]\\d{1} \\d{3}([,\\.]\\d{1,2})?)|(:?[1-4]\\d{2} \\d{3}([,\\.]\\d{1,2})?)|500 000|500 000.0|500 000.00)$",
        errorMessage: "Сумма оплаты не должна быть меньше 50000 и не привышать 500000"
    },
    bill_sum: {
        // regex: "^(?:(?:1[5-9] \\d{3}([,\\.]\\d{1,2})?)|(?:(?:[2-9]\\d{1} \\d{3}([,\\.]\\d{1,2})?)|(:?[1-4]\\d{2} \\d{3}([,\\.]\\d{1,2})?)|500 000|500 000.0|500 000.00)$",
        regex: "^(?:(?:[5-9]\\d{1} \\d{3}([,\\.]\\d{1,2})?)|(:?[1-4]\\d{2} \\d{3}([,\\.]\\d{1,2})?)|500 000|500 000.0|500 000.00)$",
        errorMessage: "Сумма оплаты не должна быть меньше 50000 и не привышать 500000"
    },
    login: {
        regex: "^.+$",
        errorMessage: "Поле не должно быть пустым."
    },
    business_manager_id: {
        regex: "^0\\d*|[1-9]\\d*$",
        errorMessage: "ID вашего Business Manager состоит только из цифр."
    }



}

export const  checkValidation = (formData, extraShemValidation = {}) => {
    const tempShemValidation = {...validationScheme, ...extraShemValidation}
    const validationAnswers = {isError: false}
    for (const formDataKey in formData) {
        const regexTest = new RegExp(tempShemValidation[formDataKey].regex, "g")
        if(regexTest.test(formData[formDataKey])){
            validationAnswers[formDataKey] = ''
        }else{
            validationAnswers[formDataKey] = tempShemValidation[formDataKey].errorMessage
            validationAnswers.isError = true
        }
    }
    return validationAnswers
}

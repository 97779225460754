export const policy = [
    `<span class="accordionIndexCardRefillPage">1</span><span class="accordionTextRefillPage">Нарушение <a target="_blank" href="https://online.zakon.kz/Document/?doc_id=1045608">Закона о рекламе РК</a>.</span>`,

    `<span class="accordionIndexCardRefillPage">2</span>
        <div>
            <span class="accordionTextRefillPage">Нарушение правил оформления объявлений:</span>
            <ul>
                <li><span class="accordionTextRefillPage">Введение пользователей в заблуждение.</span></li>
                <li><span class="accordionTextRefillPage">Ложная или неактуальная информация.</span></li>
                <li><span class="accordionTextRefillPage">Содержимое рекламного объявления не соответствует посадочной странице.</span></li>
                <li><span class="accordionTextRefillPage">Использование запрещенных ключевых слов.</span></li>
            </ul>
        </div>`,

    `<span class="accordionIndexCardRefillPage">3</span>
        <div>
            <span class="accordionTextRefillPage">Нарушение правил контента:</span>
            <ul>
                <li><span class="accordionTextRefillPage">Реклама запрещенных товаров и услуг.</span></li>
                <li><span class="accordionTextRefillPage">Контент для взрослых.</span></li>
                <li><span class="accordionTextRefillPage">Ненавистнические высказывания и дискриминация.</span></li>
                <li><span class="accordionTextRefillPage">Реклама, нарушающая авторские права.</span></li>
            </ul>
        </div>`,

    `<span class="accordionIndexCardRefillPage">4</span>
        <div>
            <span class="accordionTextRefillPage">Нарушение правил назначения рекламных кампаний:</span>
            <ul>
                <li><span class="accordionTextRefillPage">Реклама на пользователей, не соответствующих целевой аудитории.</span></li>
                <li><span class="accordionTextRefillPage">Неправильный геотаргетинг.</span></li>
                <li><span class="accordionTextRefillPage">Некорректный показ объявлений на мобильных устройствах.</span></li>
            </ul>
        </div>`,

    `<span class="accordionIndexCardRefillPage">5</span>
        <div>
            <span class="accordionTextRefillPage">Создание нескольких аккаунтов:</span>
            <ul>
                <li><span class="accordionTextRefillPage">Создание аккаунтов для обхода ограничений или мошенничества.</span></li>
                <li><span class="accordionTextRefillPage">Использование нескольких аккаунтов для одного и того же сайта или компании.</span></li>
            </ul>
        </div>`,

    `<span class="accordionIndexCardRefillPage">6</span>
        <div>
            <span class="accordionTextRefillPage">Нарушение правил использования сторонних ресурсов:</span>
            <ul>
                <li><span class="accordionTextRefillPage">Использование неавторизованных программ для работы с рекламной платформой.</span></li>
                <li><span class="accordionTextRefillPage">Автоматизация кликов по объявлениям.</span></li>
                <li><span class="accordionTextRefillPage">Искусственное увеличение количества показов.</span></li>
            </ul>
        </div>`,

    `<span class="accordionIndexCardRefillPage">7</span>
        <div>
            <span class="accordionTextRefillPage">Нарушение других правил:</span>
            <ul>
                <li><span class="accordionTextRefillPage">Спам и рассылка несанкционированных объявлений.</span></li>
                <li><span class="accordionTextRefillPage">Злоупотребление системой рекламной платформы.</span></li>
                <li><span class="accordionTextRefillPage">Несоблюдение требований рекламной платформы к рекламодателям.</span></li>
            </ul>
        </div>`,

    `<span class="accordionIndexCardRefillPage">8</span><span class="accordionTextRefillPage">В случае блокировки аккаунта, денежные средства находящиеся на аккаунте не подлежат возврату.</span>`,
]

import {ToggleButton, ToggleButtonGroup, useMediaQuery} from '@mui/material';

import {useState} from 'react';
import { useTranslation } from 'react-i18next';

import './ChartSwitcher.scss';
import CustomBarChart from '../../Charts/CustomBarChart/CustomBarChart';
import SwiperButtons from '../../Swiper/SwiperButtons';
import growth_yellow from '../../../static/images/weInDigits/growth1.svg';
import growth_blue from '../../../static/images/weInDigits/growth2.svg';
import rocket from '../../../static/images/weInDigits/rocket.svg';


export default function ChartsSwitcher() {
    const [page, setPage] = useState('shows');
    const isMobile = useMediaQuery(`(max-width: 600px)`);
    const { t, i18n } = useTranslation();

    const pages = {
        shows: {
            title: t('tds_in_numbers_block.name_btn.bt1'),
            data: [
                {
                    name: '2018',
                    uv: 13.7,
                },
                {
                    name: '2019',
                    uv: 17,
                },
                {
                    name: '2020',
                    uv: 22.8,
                },
                {
                    name: '2021',
                    uv: 26.4,
                },
                {
                    name: '2022',
                    uv: 29.1,
                },
            ],
        },
        clicks: {
            title: t('tds_in_numbers_block.name_btn.bt2'),
            data: [
                {
                    name: '2017',
                    uv: 3256,
                },
                {
                    name: '2018',
                    uv: 5367,
                },
                {
                    name: '2019',
                    uv: 7758,
                },
                {
                    name: '2020',
                    uv: 10000,
                },
                {
                    name: '2021',
                    uv: 1890,
                },
            ],
        },
        looks: {
            title: t('tds_in_numbers_block.name_btn.bt3'),
            data: [
                {
                    name: '2017',
                    uv: 4561,
                },
                {
                    name: '2018',
                    uv: 3000,
                },
                {
                    name: '2019',
                    uv: 2000,
                },
                {
                    name: '2020',
                    uv: 2780,
                },
                {
                    name: '2021',
                    uv: 1890,
                },
            ],
        },
    };
    const all = {
        title: 'ALL',
        data: [
            {name: '2019', shows: 17, clicks: 166.6, looks: 185.9},
            {name: '2020', shows: 22.8, clicks: 200.6, looks: 259.9},
            {name: '2021', shows: 26.4, clicks: 256.1, looks: 798},
            {name: '2022', shows: 29.1, clicks: 298.3, looks: 821},
            {name: '2023', shows: 33.8, clicks: 345.6, looks: 1413},
        ],
    };
    const stack = [
        {
            name: 'shows',
            color: '#F9B932',
            label: t('tds_in_numbers_block.billion'),
            side: {
                img: growth_yellow,
                title: t('tds_in_numbers_block.impressions.subtitle'),
                text:t('tds_in_numbers_block.impressions.description'),
            },
        },
        {
            name: 'clicks',
            color: '#002D55',
            label: t('tds_in_numbers_block.mln'),
            side: {
                img: growth_blue,
                title: t('tds_in_numbers_block.clicks.subtitle'),
                text:t('tds_in_numbers_block.clicks.description'),
            },
        },
        {
            name: 'looks',
            color: '#335777',
            label: t('tds_in_numbers_block.mln'),
            side: {
                img: rocket,
                title: t('tds_in_numbers_block.views.subtitle'),
                text: t('tds_in_numbers_block.views.description'),
            },
        },
    ];
    const buttons = [
        {name: 'shows', label: t('tds_in_numbers_block.name_btn.bt1')},
        {name: 'clicks', label: t('tds_in_numbers_block.name_btn.bt2')},
        {name: 'looks', label: t('tds_in_numbers_block.name_btn.bt3')},
    ];

    return (
        <div className={'graph-section'}>
            <h2 className={'font-32 w600'}>{t("tds_in_numbers_block.title")}</h2>
            {isMobile ? (
                <SwiperButtons setActiveIndex={setPage} data={buttons} />
            ) : (
                <div>
                    <ToggleButtonGroup
                        className={'standard_toggle'}
                        value={page}
                        exclusive
                        onChange={(e) => {
                            setPage(e.target.value);
                        }}
                        aria-label="Platform"
                        sx={{mt: '40px'}}
                    >
                        {Object.keys(pages).map((value) => (
                            <ToggleButton value={value} key={value}>
                                {pages[value].title}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </div>
            )}
            <div className={'graph-section--row'}>
                <div className={'leftside'}>
                    <div className={'chart-container'}>
                        <CustomBarChart
                            data={all?.data}
                            field={page}
                            name={stack.find((el) => el.name === page).label}
                            color={stack.find((el) => el.name === page).color}
                            key={page}
                        />
                    </div>
                </div>
                <div className={'info'}>
                    <img src={stack.find((el) => el.name === page).side.img} />
                    <p
                        className={'font-16 w600'}
                        style={{marginBottom: 16, marginTop: 16, textAlign: 'left'}}
                    >
                        {stack.find((el) => el.name === page).side.title}
                    </p>
                    <p className={'font-14 w400'} style={{textAlign: 'left'}}>
                        {stack.find((el) => el.name === page).side.text}
                    </p>
                </div>
            </div>
        </div>
    );
}

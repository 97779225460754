const ExpandFAQ = () => {
  return(
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="#EDEEF4"/>
          <path d="M8 11L12 15L16 11" stroke="#3E455C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

  )
}

export default ExpandFAQ
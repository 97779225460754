const MetaColumn = (props) => {
  return(
      <svg width="89" height="91" viewBox="0 0 89 91" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1413 38.8016C10.1413 42.1403 10.8761 44.7038 11.8362 46.2545C13.0949 48.2859 14.9726 49.1463 16.8868 49.1463C19.3557 49.1463 21.6145 48.5353 25.9673 42.5298C29.4542 37.716 33.5631 30.9596 36.3279 26.7236L41.0098 19.5475C44.2619 14.5635 48.0265 9.02347 52.3426 5.26796C55.8662 2.203 59.6672 0.5 63.4926 0.5C69.9151 0.5 76.0327 4.21296 80.7146 11.1764C85.8382 18.8024 88.3257 28.4085 88.3257 38.3212C88.3257 44.2143 87.1612 48.5444 85.1802 51.965C83.2657 55.2736 79.5349 58.5789 73.2588 58.5789V49.1463C78.6325 49.1463 79.9737 44.2205 79.9737 38.5826C79.9737 30.549 78.0963 21.6335 73.9597 15.2632C71.0244 10.7443 67.2205 7.98342 63.0356 7.98342C58.5089 7.98342 54.8665 11.3891 50.7728 17.4615C48.5965 20.6879 46.3621 24.6195 43.8536 29.0561L41.092 33.9365C35.5445 43.7488 34.1393 45.9839 31.3654 49.6725C26.5036 56.1311 22.3521 58.5789 16.8868 58.5789C10.4035 58.5789 6.30365 55.7784 3.76457 51.5577C1.69192 48.1186 0.67395 43.6059 0.67395 38.4641L10.1413 38.8016Z" fill="#0081FB"/>
          <path d="M8.13892 11.8422C12.4794 5.16759 18.7435 0.5 25.9278 0.5C30.0886 0.5 34.2248 1.72845 38.5439 5.24685C43.2687 9.09332 48.304 15.4272 54.5862 25.8661L56.8388 29.6125C62.2765 38.6496 65.3704 43.299 67.1812 45.4915C69.5098 48.307 71.1407 49.1463 73.2592 49.1463C78.6329 49.1463 79.9741 44.2205 79.9741 38.5826L88.3261 38.3212C88.3261 44.2143 87.1616 48.5444 85.1802 51.965C83.2661 55.2736 79.5353 58.5789 73.2592 58.5789C69.3575 58.5789 65.9008 57.7334 62.0784 54.1361C59.1401 51.3752 55.7049 46.4705 53.0622 42.0614L45.201 28.9616C41.2568 22.3877 37.6386 17.4859 35.5446 15.2661C33.292 12.879 30.3962 9.9963 25.7754 9.9963C22.0355 9.9963 18.8591 12.6146 16.2012 16.6193L8.13892 11.8422Z" fill="url(#paint0_linear_2456_482)"/>
          <path d="M25.7754 9.9963C22.0351 9.9963 18.8591 12.6146 16.2011 16.6193C12.4427 22.2782 10.1413 30.7072 10.1413 38.8016C10.1413 42.1403 10.8761 44.7038 11.8362 46.2545L3.76457 51.5577C1.69192 48.1186 0.67395 43.6059 0.67395 38.4641C0.67395 29.1136 3.24648 19.3682 8.13885 11.8422C12.4794 5.16759 18.7431 0.5 25.9277 0.5L25.7754 9.9963Z" fill="url(#paint1_linear_2456_482)"/>
          <path d="M1.1059 64.0127H6.26704L15.0424 79.8501L23.8191 64.0127H28.8682V90.0358H24.658V70.0914L16.9615 83.9027H13.0113L5.31616 70.0914V90.0358H1.1059V64.0127ZM42.0607 73.7155C39.0413 73.7155 37.2228 75.9825 36.7875 78.7897H47.0352C46.824 75.8979 45.152 73.7155 42.0607 73.7155ZM32.6132 80.3889C32.6132 74.4821 36.4398 70.1831 42.134 70.1831C47.7348 70.1831 51.0788 74.4276 51.0788 80.7042V81.8578H36.7875C37.2946 84.9114 39.3257 86.9692 42.6009 86.9692C45.2138 86.9692 46.8471 86.174 48.3956 84.7195L50.6321 87.4521C48.5248 89.3853 45.8443 90.5001 42.4515 90.5001C36.2877 90.5001 32.6132 86.0163 32.6132 80.3889ZM56.1841 74.0881H52.3084V70.6489H56.1841V64.9613H60.2463V70.6489H66.1344V74.0881H60.2463V82.805C60.2463 85.7813 61.2001 86.8389 63.5444 86.8389C64.6146 86.8389 65.2279 86.7471 66.1344 86.5966V89.9986C65.0053 90.3167 63.928 90.4629 62.7616 90.4629C58.3761 90.4629 56.1841 88.0727 56.1841 83.2879V74.0881ZM83.24 77.4326C82.4241 75.3777 80.6041 73.8645 77.9299 73.8645C74.4529 73.8645 72.2278 76.3249 72.2278 80.333C72.2278 84.2408 74.2762 86.8202 77.763 86.8202C80.503 86.8202 82.4593 85.2297 83.24 83.2506V77.4326ZM87.3025 90.0358H83.315V87.3217C82.1991 88.9196 80.1709 90.5001 76.8859 90.5001C71.6029 90.5001 68.0722 86.0879 68.0722 80.333C68.0722 74.5237 71.6877 70.1831 77.111 70.1831C79.7913 70.1831 81.8945 71.2521 83.315 73.1394V70.6489H87.3025V90.0358Z" fill={props.textColor}/>
          <defs>
              <linearGradient id="paint0_linear_2456_482" x1="19.2677" y1="36.077" x2="79.6197" y2="39.1326" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0064E1"/>
                  <stop offset="0.4" stopColor="#0064E1"/>
                  <stop offset="0.83" stopColor="#0073EE"/>
                  <stop offset="1" stopColor="#0082FB"/>
              </linearGradient>
              <linearGradient id="paint1_linear_2456_482" x1="14.3906" y1="42.7669" x2="14.3906" y2="20.5694" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0082FB"/>
                  <stop offset="1" stopColor="#0064E0"/>
              </linearGradient>
          </defs>
      </svg>

  )
}

export default MetaColumn
import axios from "axios";

const base_URl = "https://team-test.tds.media"



export async function get_industry() {
    return await axios.get( base_URl + "/api/fills/account_creation/industries/")
}

export async  function create_application({system, payLoad}) {
    const formData = new FormData()
    for (const key in payLoad) {
        formData.append(key,payLoad[key])
    }
    return await (await axios.post(`${base_URl}/api/fills/account_creation/${system}/`, formData)).data
}

export async function get_redirect_url (formData) {
    return await (await axios.post(`${base_URl}/api/fills/account_fill/`, formData)).data
}


import {Link} from "react-router-dom";
import {ReactComponent as LogoIcon} from '../../../static/images/LogoIcon.svg';
import Button from "@mui/material/Button";
import s from "./header.module.scss"
import {useContext} from "react";
import RefillModalContext from "../../../context/RefillModalContext";


const HeaderRefill = () => {
    const { setOpenModal, setMode} = useContext(RefillModalContext)

    const handleClick = (typeEvent) => {
      switch (typeEvent) {
          case "create":{
              setMode("step1")
              setOpenModal(true)
              break
          }
          case "refill":{
              setMode("refill")
              setOpenModal(true)
              break
          }
          default: break
      }
    }
  return(
      <header className={s.headerRefill}>
          <div className={s.headerContainer}>
              <Link to="/">
                  <LogoIcon />
              </Link>
              <div className={s.containerBtn}>
                  <Button
                      onClick={()=> handleClick("create")}
                      className={`${s.headerBtn} ${s.createAccBtn}`}
                  >
                      Создать аккаунт
                  </Button>

                  <Button
                      onClick={()=> handleClick("refill")}
                      className={`${s.headerBtn} ${s.refillAccBtn}`}
                  >
                      <span>Пополнение <span className={s.visibleWord}>аккаунта</span></span>
                  </Button>
              </div>
          </div>
      </header>
  )
}

export default HeaderRefill
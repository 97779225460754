export const getInitialFormDataStepOne = (ad_system) => {
    const tempData = {
        ad_system: null,
        first_name : "",
        last_name : "",
        email : "",
        organization_type : null,
        promocode : "",
        sub_industry: null,
        industry: null,
    }
    switch (ad_system) {
        case "TikTok" :{
            tempData.phone_number = ""
            tempData.placement = ""
            return tempData
        }
        case "Facebook":{
            tempData.business_manager_id = ""
            return tempData
        }
        default: return tempData
    }
}




export const inputData = [
    {label: "Имя", name: "first_name", type: "text"},
    {label: "Фамилия", name: "last_name", type: "text"},
    {label: "Email", name: "email", type: "text"},
    {label: "Телефон", name: "phone_number", type: "number"},
    {label: "Сайт", name: "placement", type: "text"},
]

export const inputPsKz = {label: "Промокод от ps.kz", name: "promocode", type: "text"}

export const selectOptionOrganization = [
    {title:"Юридическое лицо", value: "Юр. лицо",},
    {title:"Физическое лицо", value: "Физ. лицо"}
]
export const selectOptionSystem = [
    {title: "Google", value: "Google Ads"},
    {title: "Yandex", value: "Yandex Direct"},
    {title: "TikTok", value: "TikTok"},
    {title: "Meta", value: "Facebook"},
]

export const generalFormDataStepTwo = {
    name: "",
    company_type: null,
    bin_number: "",
    reference: undefined
}

export const selectOptionCompanyType = [
    {title:"ИП", value: "ИП",},
    {title:"ТОО", value: "ТОО"}
]
const Yandex = (props) => {
  return(
      <svg width="232" height="57" viewBox="0 0 232 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.0766 36.3477H16.3789V17.353H11.5677C6.73064 17.353 4.19571 19.8362 4.19571 23.5006C4.19571 26.4235 5.58389 28.148 8.0757 29.9241L3.75598 36.3477H7.33419L12.1454 29.1568L10.4813 28.0359C8.45508 26.6736 7.47215 25.6044 7.47215 23.3109C7.47215 21.2847 8.89481 19.9224 11.6022 19.9224H13.0766V36.3477ZM76.8378 36.615C78.3466 36.615 79.4072 36.3477 80.2004 35.77V33.123C79.3813 33.692 78.3984 34.0455 77.0361 34.0455C74.7081 34.0455 73.751 32.2435 73.751 29.4068C73.751 26.4235 74.9322 24.8974 77.0619 24.8974C78.3208 24.8974 79.5451 25.3285 80.2004 25.7424V22.9833C79.5192 22.5953 78.3208 22.328 76.7084 22.328C72.5525 22.328 70.3883 25.3026 70.3883 29.4844C70.3883 34.08 72.4922 36.615 76.8378 36.615ZM56.5066 35.4165V32.7608C55.4978 33.4506 53.7992 34.0455 52.2127 34.0455C49.833 34.0455 48.9363 32.9333 48.7984 30.6312H56.6446V28.9153C56.6446 24.13 54.5407 22.328 51.2815 22.328C47.324 22.328 45.4357 25.3544 45.4357 29.5103C45.4357 34.2956 47.7896 36.615 51.9455 36.615C54.0148 36.615 55.5495 36.0718 56.5066 35.4165ZM66.8963 36.3477H70.578L65.3616 29.0447L69.9572 22.5953H66.6722L62.0852 29.0447V22.5953H58.826V36.3477H62.0852V29.7L66.8963 36.3477ZM44.6425 33.7783H43.1939V22.5953H33.7095V23.7765C33.7095 27.1305 33.4939 31.4761 32.3472 33.7783H31.3298V39.4862H34.3389V36.3477H41.6333V39.4862H44.6425V33.7783ZM26.7169 22.5953V28.0359H22.3713V22.5953H19.1121V36.3477H22.3713V30.6053H26.7169V36.3477H29.9675V22.5953H26.7169ZM51.2298 24.8974C52.8422 24.8974 53.3336 26.2338 53.3336 27.9583V28.2256H48.7984C48.8846 26.0441 49.6692 24.8974 51.2298 24.8974ZM39.9434 33.7783H35.3477C36.2531 31.7003 36.5031 27.9583 36.5031 25.5785V25.1647H39.9434V33.7783Z" fill={`${props.textColor}`}/>
          <g clipPath="url(#clip0_2418_55)">
              <path d="M85.7452 28.4196C85.7452 21.0667 88.6662 14.0149 93.8655 8.81565C99.0647 3.61636 106.116 0.695435 113.469 0.695435C120.822 0.695435 127.874 3.61636 133.073 8.81565C138.273 14.0149 141.194 21.0667 141.194 28.4196C141.194 35.7725 138.273 42.8242 133.073 48.0235C127.874 53.2228 120.822 56.1437 113.469 56.1437C106.116 56.1437 99.0647 53.2228 93.8655 48.0235C88.6662 42.8242 85.7452 35.7725 85.7452 28.4196Z" fill="#1A4A7F"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M108.936 55.7694C103.38 54.8567 98.2339 52.2693 94.1872 48.3532L119.167 25.4254L104.889 28.8909C103.944 29.1232 102.962 29.165 102 29.0139C101.039 28.8629 100.117 28.5219 99.2887 28.011C98.4604 27.5001 97.742 26.8294 97.1756 26.038C96.6091 25.2466 96.2058 24.3503 95.9893 23.4015C95.5503 21.4708 95.8868 19.4449 96.9263 17.7598C97.9659 16.0747 99.6255 14.865 101.548 14.3911L131.268 7.16901C134.888 10.1999 137.674 14.1049 139.361 18.5139C141.049 22.923 141.583 27.69 140.912 32.3631C140.242 37.0363 138.389 41.4608 135.53 45.2173C132.67 48.9738 128.899 51.938 124.573 53.8287C124.49 52.8445 124.587 51.8187 124.933 50.8207L129.646 36.7645L108.936 55.7694Z" fill="url(#paint0_linear_2418_55)"/>
          </g>
          <path d="M149.825 39.8957V35.9381H159.508V39.8957H162.595V33.3687H160.818V16.9434H149.661L149.394 24.7551C149.256 29.2645 148.903 31.9202 148.023 33.3687H146.738V39.8957H149.825ZM152.507 25.1949L152.696 19.5128H157.507V33.3687H151.36C151.938 32.0581 152.369 29.7043 152.507 25.1949ZM164.776 22.1857V35.9381H167.673L172.648 27.4366V35.9381H175.795V22.1857H172.898L167.923 30.6872V22.1857H164.776ZM181.538 22.1857H178.528V39.8957H181.779V34.3258C182.598 35.5501 183.805 36.2054 185.193 36.2054C188.366 36.2054 190.556 33.6705 190.556 29.049C190.556 24.4534 188.427 21.9184 185.357 21.9184C183.831 21.9184 182.572 22.6255 181.701 23.9619L181.538 22.1857ZM184.374 33.636C182.658 33.636 181.779 32.2478 181.779 29.0749C181.779 25.876 182.71 24.4878 184.538 24.4878C186.314 24.4878 187.194 25.876 187.194 29.049C187.194 32.2478 186.263 33.636 184.374 33.636ZM203.265 35.0069V32.3513C202.257 33.0411 200.558 33.636 198.972 33.636C196.592 33.636 195.695 32.5237 195.557 30.2216H203.403V28.5058C203.403 23.7205 201.3 21.9184 198.04 21.9184C194.083 21.9184 192.195 24.9448 192.195 29.1007C192.195 33.886 194.548 36.2054 198.696 36.2054C200.774 36.2054 202.308 35.6622 203.265 35.0069ZM197.989 24.4878C199.601 24.4878 200.093 25.8243 200.093 27.5487V27.816H195.557C195.635 25.6346 196.428 24.4878 197.989 24.4878ZM213.647 35.9381H217.337L212.12 28.6351L216.708 22.1857H213.431L208.844 28.6351V22.1857H205.585V35.9381H208.844V29.2904L213.647 35.9381ZM228.244 24.7551V22.1857H217.337V24.7551H221.165V35.9381H224.416V24.7551H228.244Z" fill={`${props.textColor}`}/>
          <defs>
              <linearGradient id="paint0_linear_2418_55" x1="153.753" y1="4.20253" x2="58.8389" y2="91.2979" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#FFB800"/>
                  <stop offset="1" stopColor="#FFF11D"/>
              </linearGradient>
              <clipPath id="clip0_2418_55">
                  <rect width="55.4483" height="55.4483" fill="white" transform="translate(85.7452 0.695435)"/>
              </clipPath>
          </defs>
      </svg>

  )
}

export default Yandex
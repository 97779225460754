import s from "./homeBlockRefiil.module.scss"
import TDS_logo from '../../../static/images/TDS_biglogo.png';
import BackgroundVideo from "../../../static/video/background.mp4";
import {partners} from "../constRefill/const";
const HomeBlockRefill = () => {
  return (
      <section className={s.homeBLock}>
          <div className={s.ContentContainer}>

              <div className={s.logoWrap}>
                  <img className={s.logoImg} src={TDS_logo}  alt={'TDS Media Logo'}/>
              </div>

              <h1 className={s.mainTitle}>Создай рекламные аккаунты выгодно</h1>

              <div className={s.partnersContainer}>
                  {
                      partners.map((partnerItem, index) => {
                          const {img, description, link} = partnerItem
                          return (
                              <div key={index} className={s.partnerCard}>
                                  {
                                      img({textColor: "#fff"})
                                  }
                                  {
                                      description && <span className={s.partnerTitle}>{description}</span>
                                  }
                              </div>
                          )
                      })
                  }
              </div>
          </div>

          <video autoPlay muted loop className={s.video}>
              <source src={BackgroundVideo} type="video/mp4" />
          </video>

      </section>
  )
}

export default  HomeBlockRefill
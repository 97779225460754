import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {ReactComponent as LogoIcon} from '../../../static/images/LogoIcon.svg';
import {AppBar} from '@mui/material';
import CustomModal from '../../../Components/Modal';
import PayInForm from '../../../Components/Forms/PayInForm';
import CertificateForm from '../../../Components/Forms/Certificate';
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import {SelectLang} from "../../../Components/SelectLang/SelectLang";



export const Header = ({active}) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const { t, i18n } = useTranslation();
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const [openCert, setOpenCert] = useState(false);
    const handleOpenCert = () => {
        history.pushState({}, null, 'promo');
        setOpenCert(true);
    };
    const handleCloseCert = () => {
        history.pushState({}, null, '/');
        setOpenCert(false);
    };
    useEffect(() => {
        if (window.location.toString().includes('/promo')) {
            handleOpenCert();
        }
    }, []);

    const pages = [
        {title: t("header.services"), link: '#service'},
        {title: t("header.our_team"), link: '#command'},
        {title: t("header.contact"), link: '#contacts'},
        // {title: 'TikTok', link: '/tiktok'},
    ];

    return (
        <AppBar
            position="fixed"
            sx={{
                background: `${active ? 'white' : 'transparent'}`,
                zIndex: '999',
                width: '100%',
                transition: '0.3s all linear',
                boxShadow: `${active ? 'inherit' : 'none'}`,
                height: 68,
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Container
                disableGutters
                maxWidth="xl"
                sx={{width: {xs: 'none', md: '80%'}, padding: {xs: '0 12px 0 12px', md: '0'}}}
            >
                <Toolbar disableGutters>
                    <Link to="/">
                        <LogoIcon />
                    </Link>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: {xs: 'flex', md: 'none'},
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <SelectLang active={active}/>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            sx={{border: 'none', color: 'black'}}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page, index) => (
                                <Typography
                                    key={index}
                                    href={page.link}
                                    component={'a'}
                                    variant={'subtitle2'}
                                    sx={{textDecoration: 'none'}}
                                >
                                    <MenuItem
                                        sx={{
                                            fontSize: '14px',
                                            color: '#0F2C52',
                                        }}
                                        onClick={handleCloseNavMenu}
                                    >
                                        {page.title}
                                    </MenuItem>
                                </Typography>
                            ))}


                            {/*<Typography*/}
                            {/*    component={'a'}*/}
                            {/*    variant={'subtitle2'}*/}
                            {/*    sx={{textDecoration: 'none'}}*/}
                            {/*>*/}
                                {/*<MenuItem*/}
                                {/*    sx={{*/}
                                {/*        fontSize: '14px',*/}
                                {/*        color: '#0F2C52',*/}
                                {/*    }}*/}
                                {/*    onClick={handleOpenCert}*/}
                                {/*>*/}
                                {/*    Сертификат*/}
                                {/*</MenuItem>*/}
                            {/*</Typography>*/}




                            <Typography
                                component={'a'}
                                variant={'subtitle2'}
                                sx={{textDecoration: 'none'}}
                            >
                                <MenuItem
                                    // onClick={handleOpen}
                                >
                                    <Link to={"/refill"} style={{
                                        fontSize: '14px',
                                        color: '#0F2C52',
                                        textDecoration: "none"
                                    }}>
                                        {
                                            t("header.replenishment")
                                        }
                                    </Link>
                                </MenuItem>
                            </Typography>
                        </Menu>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: {xs: 'none', md: 'flex'},
                            justifyContent: 'flex-end',
                            gap: '8px',
                        }}
                    >
                        {pages.map(({title, link}, index) => (
                            <a
                                key={index}
                                href={link}
                                style={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    textDecoration: 'none',
                                }}
                            >
                                <Button
                                    style={{
                                        fontSize: 16,
                                        textTransform: 'none',
                                        color: `${active ? '#0F2C52' : 'white'}`,
                                        lineHeight: 1,
                                    }}
                                    sx={{padding: '16px 32px!important'}}
                                    onClick={handleCloseNavMenu}
                                    className="button-transparent"
                                >
                                    {title}
                                </Button>
                            </a>
                        ))}
                        {/*<a*/}
                        {/*    style={{*/}
                        {/*        display: 'flex',*/}
                        {/*        alignContent: 'center',*/}
                        {/*        textDecoration: 'none',*/}
                        {/*    }}*/}
                        {/*>*/}
                            {/*<Button*/}
                            {/*    style={{*/}
                            {/*        fontSize: 16,*/}
                            {/*        textTransform: 'none',*/}
                            {/*        color: `${active ? '#0F2C52' : 'white'}`,*/}
                            {/*        lineHeight: 1,*/}
                            {/*    }}*/}
                            {/*    sx={{padding: '16px 32px!important'}}*/}
                            {/*    // onClick={handleOpenCert}*/}
                            {/*    onClick={handleOpenCert}*/}
                            {/*    className="button-transparent"*/}
                            {/*>*/}
                            {/*    Сертификат*/}
                            {/*</Button>*/}
                        {/*</a>*/}

                        <SelectLang active={active}/>


                        <Box>
                            <Link to={"/refill"}>
                                <Button className={'button-filled'}
                                        // onClick={handleOpen}
                                >
                                    {
                                        t("header.replenishment")
                                    }
                                </Button>
                            </Link>
                        </Box>
                        <CustomModal open={open} setOpen={setOpen}>
                            <PayInForm />
                        </CustomModal>
                        <CustomModal
                            open={openCert}
                            setOpen={setOpenCert}
                            handleClose={handleCloseCert}
                        >
                            <CertificateForm close={handleCloseCert} />
                        </CustomModal>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

import CreateAccStepOne from "../CreateAccStepOne/CreateAccStepOne";
import CreateAccStepTwo from "../CreateAccStepTwo/CreateAccStepTwo";
import CreateAccStepThree from "../CreateAccStepThree/CreateAccStepThree";
import Refill from "../Refill/Refill";
import FullfiledAnswer from "../FulfiledAnswer/FullfiledAnswer";
import RejectAnswer from "../RejectAnswer/RejectAnswer";
import {useContext} from "react";
import RefillModalContext from "../../../../context/RefillModalContext";
import Loading from "../Loading/Loading";

const getTemplateModal = () => {

    const {mode} = useContext(RefillModalContext)

    const modalTemplate = {
        "step1": CreateAccStepOne,
        "step2": CreateAccStepTwo,
        "step3": CreateAccStepThree,
        "refill": Refill,
        "loading":  Loading,
        "fullfiled": FullfiledAnswer,
        "reject": RejectAnswer
    }

  return modalTemplate[mode]
}

export default getTemplateModal
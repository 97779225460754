import CustomInput from "../../CustomInput/CustomInput";
import React, {useContext, useEffect, useState} from "react";
import  s from "./index.module.scss"
import Button from "@mui/material/Button";
import {Checkbox} from "@mui/material";
import CustomSelect from "../../CustomSelect/CustomSelect";
import RefillModalContext from "../../../../context/RefillModalContext";
import {checkValidation} from "../../constRefill/validationSheme";
import {
    getInitialFormDataStepOne,
    inputData,
    inputPsKz,
    selectOptionOrganization, selectOptionSystem,
} from "../../constRefill/stepOneModalConst";
import useValidation from "../../../../Components/hooks/useValidation";

const CreateAccStepOne = () => {
    const {
        setMode, typeSystem, setTypeSystem, typeOrganization,
        setTypeOrganization, stepOneFormData, setStepOneFormData,
        subIndustryData, setSubIndustryData, industriesData,
    } = useContext(RefillModalContext)
    const [trustCheck, setTrustCheck] = useState([{name: "policy", checked: false},{name: "offer", checked:false}])
    const { errorObj, validate, setErrorObj } = useValidation()
    const handleChecked = (e) => {
        setTrustCheck(trustCheck.map(trustedElem => (
            e.currentTarget.name === trustedElem.name ? {...trustedElem, checked: e.currentTarget.checked} : trustedElem
        )))
    }


    const handleOnClick = () => {
       const isError =  validate(stepOneFormData);
        if(!isError){
            switch (typeOrganization) {
                case "Юр. лицо": {
                    setMode("step2")
                    break
                }
                case "Физ. лицо": {
                    setMode("step3")

                    break
                }
                default: break
            }
            setErrorObj({})
        }
    }

   const  handleSelectChange = async (e) => {
        const typeSelect = e.target.name
       switch (typeSelect) {
           case "ad_system":{
               setTypeSystem(e.target.value);
               setStepOneFormData(getInitialFormDataStepOne(e.target.value))
               break
           }
           case "organization_type":{
               setTypeOrganization(e.target.value)
               break
           }
           case "industry":{
               setSubIndustryData(industriesData.subindustries[e.target.value])
               break
           }

           default: break
       }
       handleOnChange(e);
    }
    const handleOnChange = (e) => {
        setStepOneFormData({...stepOneFormData, [e.target.name]: e.target.value})
    }
  return(
      <div className={s.createAccBlock}>
          <h2 className={s.title}>Создание аккаунта</h2>
          <div className={s.inputContainer}>

              <CustomSelect
                  name="ad_system"
                  label="Выберите систему"
                  selectValue={stepOneFormData.ad_system}
                  selectData={selectOptionSystem}
                  placeholder="Выберите систему"
                  selectOnChange={handleSelectChange}
                  onBlur={validate}
                  helperText={errorObj.ad_system}
                  error={!!errorObj.ad_system}
              />

              <CustomSelect
                  label="Тип организации"
                  name="organization_type"
                  selectValue={stepOneFormData.organization_type}
                  selectData={selectOptionOrganization}
                  placeholder="Тип организации"
                  selectOnChange={handleSelectChange}
                  onBlur={validate}
                  helperText={errorObj.organization_type}
                  error={!!errorObj.organization_type}
              />

              {
                  typeSystem && inputData.slice(0 , typeSystem === "TikTok" ? inputData.length : 3).map((inputElem, index) => {
                      return(
                          <CustomInput
                              key={index}
                              width={320}
                              value={stepOneFormData[inputElem.name] || ''}
                              onBlur={validate}
                              onChange={handleOnChange}
                              helperText={errorObj[inputElem.name]}
                              error={!!errorObj[inputElem.name]}
                              {...inputElem}
                          />
                      )
                  })
              }

              <CustomSelect
                  label="Отрасль"
                  name="industry"
                  selectValue={stepOneFormData.industry}
                  selectData={ industriesData.industries ? industriesData.industries : [] }
                  placeholder="Отрасль"
                  selectOnChange={handleSelectChange}
                  helperText={errorObj.industry}
                  error={!!errorObj.industry}
                  onBlur={validate}
              />

              <CustomSelect
                  label="Подотрасль"
                  name="sub_industry"
                  selectValue={stepOneFormData.sub_industry}
                  selectData={ subIndustryData }
                  placeholder="Подотрасль"
                  selectOnChange={handleSelectChange}
                  onBlur={validate}

                  helperText={errorObj.sub_industry}
                  error={!!errorObj.sub_industry}
              />

              <CustomInput
                  width={320}
                  value={stepOneFormData[inputPsKz.name] || ""}
                  onChange={handleOnChange}
                  onBlur={validate}
                  helperText={errorObj[inputPsKz.name]}
                  error={!!errorObj[inputPsKz.name]}
                  {...inputPsKz}
              />

              {
                  typeSystem === "Facebook" &&
                  <div className={s.metaInputContainer} style={{width: "100%", display: "flex", alignItems: "center", gap: "16px"}}>
                      <CustomInput
                          width={320}
                          name="business_manager_id"
                          type="number"
                          value={stepOneFormData.business_manager_id || ''}
                          onChange={handleOnChange}
                          helperText={errorObj.business_manager_id}
                          error={!!errorObj.business_manager_id}
                          placeholder={"ID вашего Business Manager"}

                      />
                      <span className={s.checkBoxText}>Инструкция для получения <a className={s.link} href="https://www.facebook.com/business/help/1181250022022158?id=180505742745347" target="_blank">ID вашего Business Manager</a>.</span>
                  </div>
              }

          </div>

          <div>
              <div className={s.checkBoxContainer}>
                  <Checkbox
                    name="policy"
                    onChange={handleChecked}
                  />
                  <span className={s.checkBoxText}>Ознакомлен и согласен с политикой в отношении рекламы Google, Meta, Яндекс и TikTok.</span>
              </div>
              <div className={s.checkBoxContainer}>
                  <Checkbox
                      name="offer"
                      onChange={handleChecked}
                  />
                  <span className={s.checkBoxText}>Ознакомлен и согласен с <a className={s.link} href="https://tds.media/offerts/public-offert.pdf" target="_blank">офертой</a>.</span>
              </div>

          </div>
          <Button
              sx={{
                  "&:hover": {
                      background: "#fdba2f"
                  },
                  "&:disabled":{
                      background: "#6666"
                  }
              }}
              disabled={!(trustCheck[0].checked && trustCheck[1].checked)}
              onClick={handleOnClick}
              className={s.nextBtn}
          >
              Продолжить
          </Button>
      </div>
  )
}

export default CreateAccStepOne
const TikTokColumn = (props) => {
  return(
      <svg width="95" height="91" viewBox="0 0 95 91" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.2454 74.102H11.1475V90.4999H6.02014V74.102H0.902733L0.892822 69.6071H16.6964L15.2454 74.102ZM56.504 74.102H52.079V90.4999H46.9916V74.102H41.8846L41.875 69.6071H57.9464L56.504 74.102ZM22.3214 90.4999H17.5375L17.5 76.3035H22.3214V90.4999ZM29.253 79.3129L34.2705 74.5032H40.3069L33.9668 80.5439L41.0714 90.4999H35.4954L30.7525 83.5838L29.253 85.0072V90.4999H24.1964V69.6071H29.253V79.3129ZM82.3477 79.3129L87.3552 74.5032H93.3917L87.0515 80.5439L94.1071 90.4999H88.5311L83.7981 83.5838L82.3477 85.0072V90.4999H77.2811L77.2321 69.6071H82.3477V79.3129Z" fill={props.textColor}/>
          <path d="M19.9109 74.4285C21.2423 74.4285 22.3216 73.3492 22.3216 72.0178C22.3216 70.6864 21.2423 69.6071 19.9109 69.6071C18.5795 69.6071 17.5002 70.6864 17.5002 72.0178C17.5002 73.3492 18.5795 74.4285 19.9109 74.4285Z" fill={props.textColor}/>
          <path d="M57.2586 81.914C57.2599 77.433 60.7091 73.7069 65.1791 73.3571H64.4307C59.8879 73.622 56.3398 77.3808 56.3398 81.9285C56.3398 86.4762 59.8881 90.235 64.4307 90.4999H65.1791C60.6979 90.1493 57.2447 86.406 57.2586 81.914Z" fill="#25F4EE"/>
          <path d="M67.0117 73.4152H66.2515C70.7194 73.7821 74.1573 77.4973 74.1573 81.9577C74.1573 86.4178 70.7191 90.1327 66.2515 90.4999H67.0117C71.7694 90.4999 75.6265 86.6623 75.6265 81.9285C75.6265 77.1946 71.7694 73.3571 67.0117 73.3571V73.4152Z" fill="#FE2C55"/>
          <path d="M65.7143 86.0979C63.4115 86.0979 61.5448 84.231 61.5448 81.9285C61.5448 79.6257 63.4118 77.759 65.7143 77.759C68.017 77.759 69.8837 79.626 69.8837 81.9285C69.8784 84.2288 68.0146 86.0926 65.7143 86.0979ZM65.7143 73.3571C60.9804 73.3571 57.1428 77.1946 57.1428 81.9285C57.1428 86.6623 60.9804 90.4999 65.7143 90.4999C70.4481 90.4999 74.2857 86.6623 74.2857 81.9285C74.2857 79.6552 73.3826 77.475 71.7752 75.8676C70.1677 74.2601 67.9875 73.3571 65.7143 73.3571Z" fill={props.textColor}/>
          <path d="M42.14 23.6196V21.3315C41.341 21.2057 40.5341 21.1368 39.7253 21.1253C31.8948 21.1087 24.9618 26.1538 22.6106 33.5796C20.2588 41.0056 23.0346 49.0893 29.4624 53.5357C24.6476 48.4124 23.3105 40.9545 26.0488 34.4927C28.7871 28.0309 35.089 23.7725 42.14 23.6196Z" fill="#25F4EE"/>
          <path d="M42.522 49.25C46.8589 49.2441 50.4238 45.8356 50.6137 41.5135V2.94205H57.6776C57.5335 2.13618 57.4647 1.31863 57.4722 0.5L47.8106 0.5V39.0339C47.6499 43.3783 44.0766 46.8195 39.7186 46.8264C38.4167 46.8154 37.1358 46.496 35.9812 45.8943C36.7305 46.9289 37.7134 47.772 38.8499 48.3551C39.9865 48.9382 41.2446 49.2448 42.522 49.25ZM71.0705 16.0357V13.9204C68.4991 13.9218 65.9841 13.167 63.8383 11.75C65.7212 13.9242 68.2592 15.4282 71.0705 16.0357Z" fill="#25F4EE"/>
          <path d="M63.302 11.4822C61.2638 9.11193 60.1404 6.06505 60.1412 2.91077H57.677C58.3201 6.4355 60.3542 9.53487 63.302 11.4822ZM39.5631 30.7366C35.8104 30.7556 32.553 33.3075 31.6616 36.9257C30.7704 40.5442 32.4745 44.3001 35.7946 46.0358C34.9127 44.8294 34.3841 43.4013 34.2679 41.9115C34.1517 40.4217 34.4526 38.9289 35.1368 37.6004C36.5323 34.8897 39.3413 33.1843 42.408 33.1867C43.2258 33.1968 44.0379 33.3227 44.8198 33.5606V23.7227C44.0213 23.604 43.2153 23.5415 42.408 23.5358H41.9746V31.017C41.1885 30.8079 40.3763 30.7134 39.5631 30.7366Z" fill="#FE2C55"/>
          <path d="M70.9261 16.3036V23.7639C66.0781 23.7548 61.3561 22.2345 57.4287 19.4182V39.0206C57.4078 48.8022 49.3989 56.7206 39.5262 56.7206C35.8642 56.7283 32.2886 55.6082 29.2855 53.5124C34.2859 58.8409 42.0677 60.5946 48.9023 57.934C55.737 55.2731 60.2321 48.7395 60.2335 41.464V21.9176C64.1742 24.7151 68.9021 26.2156 73.7498 26.2073V16.602C72.8009 16.599 71.8547 16.499 70.9261 16.3036Z" fill="#FE2C55"/>
          <path d="M57.5442 38.9453V19.2305C61.4909 22.0478 66.2216 23.5568 71.0707 23.5449V16.0411C68.2071 15.44 65.6157 13.9261 63.6859 11.7268C60.576 9.72425 58.43 6.53595 57.7515 2.91077H50.6302V41.7401C50.488 45.2043 48.1576 48.1987 44.8242 49.2008C41.4907 50.2026 37.8856 48.9929 35.8416 46.1858C32.522 44.4452 30.8182 40.6781 31.7093 37.0492C32.6002 33.4202 35.8576 30.8609 39.6095 30.8416C40.4273 30.8488 41.2397 30.9755 42.0208 31.2168V23.7136C34.9402 23.8331 28.5995 28.1065 25.8572 34.6076C23.1151 41.1085 24.4895 48.6088 29.361 53.7268C32.3922 55.7638 35.977 56.8181 39.6283 56.7466C49.5085 56.7466 57.5235 48.7827 57.5442 38.9453Z" fill={props.textColor}/>
      </svg>

  )
}

export default TikTokColumn
import Landing from './Pages/Landing/Landing';
import {MessageProvider} from './context/MessageProvider';
import { Route, Routes } from "react-router-dom";
import TikTokPage from "./Pages/TikTokPage/TikTokPage";
import {RefillPage} from "./Pages/RefillPage/RefillPage";
import {RefillModalProvider} from "./context/RefillModalContext";

function App() {
    return (
        <>
            <MessageProvider>
                <RefillModalProvider>
                    <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route path="/en" element={<Landing />} />
                        <Route path="/refill" element={<RefillPage />} />
                        {/* <Route path="/tiktok" element={<TikTokPage />} /> */}
                    </Routes>
                </RefillModalProvider>
            </MessageProvider>
        </>
    );
}

export default App;

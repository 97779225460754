import {policy} from "./policy";
import {one, two, three, four, five} from "./getAcc"


export const Yandex = {
    "Как получить аккаунт с Cashback 10%": [
        one,
        two,
        three,
        four,
        five,
        `<span class="accordionIndexCardRefillPage">6</span><span class="accordionTextRefillPage">Cashback в размере 10% будет предоставлен автоматически на первое пополнение рекламного аккаунта.</span>`
    ],
    "Условия применения Cashback": [
        `<span class="accordionIndexCardRefillPage">1</span><span class="accordionTextRefillPage">Cashback от <b>ps.kz</b> действует лишь на 1 рекламный аккаунт.</span>`,
        `<span class="accordionIndexCardRefillPage">2</span><span class="accordionTextRefillPage">Cashback 10% будет предоставлен сумму первого пополнения, размер cashback на последующие пополнения составит 5%.</span>`
    ],
    "Условия пополнения аккаунта": [
        `<span class="accordionIndexCardRefillPage">1</span><span class="accordionTextRefillPage">Минимальная сумма пополнения10 000 ₸ без НДС.</span>`,
        `<span class="accordionIndexCardRefillPage">2</span><span class="accordionTextRefillPage">Аккаунт пополняется с cashback 10% на первое пополнение и 5% на последующие без НДС</span>`,
        `<span class="accordionIndexCardRefillPage">3</span><span class="accordionTextRefillPage">Пополнение <b>физических лиц</b> осуществляется в течении часа,с момента поступления денежных средств на расчетный счет.</span>`,
        `<span class="accordionIndexCardRefillPage">4</span><span class="accordionTextRefillPage">Пополнение <b>юридических лиц</b> осуществляется не ранее 12.00 часов рабочего дня, следующего за днем поступления денежных средств на расчетный счет.</span>`,
    ],
    "Нарушения за которые аккаунт может быть заблокирован": policy
}

    // `<span class="accordionIndexCardRefillPage">2</span><span class="accordionTextRefillPage"></span>`

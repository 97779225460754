const Meta = (props) => {
  return(
      <svg width="226" height="54" viewBox="0 0 226 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2551_231)">
              <path d="M32.7993 32.2783C32.7993 34.2583 33.235 35.7786 33.8044 36.6982C34.5509 37.9029 35.6644 38.4132 36.7996 38.4132C38.2638 38.4132 39.6033 38.0508 42.1848 34.4893C44.2527 31.6345 46.6894 27.6276 48.3291 25.1156L51.1057 20.8598C53.0343 17.9041 55.2668 14.6186 57.8265 12.3914C59.9162 10.5737 62.1703 9.56378 64.4389 9.56378C68.2478 9.56378 71.8758 11.7657 74.6523 15.8953C77.6909 20.4179 79.166 26.1147 79.166 31.9934C79.166 35.4883 78.4755 38.0562 77.3006 40.0848C76.1652 42.0469 73.9527 44.0072 70.2307 44.0072V38.4132C73.4176 38.4132 74.2129 35.4919 74.2129 32.1485C74.2129 27.3842 73.0995 22.0969 70.6464 18.319C68.9056 15.6391 66.6497 14.0018 64.1679 14.0018C61.4834 14.0018 59.3232 16.0215 56.8955 19.6227C55.6049 21.5361 54.2798 23.8677 52.7921 26.4988L51.1544 29.3931C47.8645 35.2122 47.0311 36.5377 45.3861 38.7252C42.5028 42.5555 40.0408 44.0072 36.7996 44.0072C32.9547 44.0072 30.5234 42.3463 29.0176 39.8433C27.7884 37.8037 27.1847 35.1275 27.1847 32.0782L32.7993 32.2783Z" fill="#0081FB"/>
              <path d="M31.6116 16.2902C34.1857 12.3319 37.9006 9.56378 42.1611 9.56378C44.6287 9.56378 47.0817 10.2923 49.6431 12.3789C52.4451 14.66 55.4312 18.4163 59.1569 24.607L60.4928 26.8288C63.7176 32.1882 65.5524 34.9455 66.6263 36.2457C68.0072 37.9154 68.9744 38.4132 70.2308 38.4132C73.4176 38.4132 74.213 35.4919 74.213 32.1485L79.1661 31.9934C79.1661 35.4883 78.4755 38.0562 77.3005 40.0848C76.1653 42.0469 73.9528 44.0072 70.2308 44.0072C67.9169 44.0072 65.8669 43.5057 63.6001 41.3724C61.8575 39.735 59.8203 36.8263 58.253 34.2115L53.591 26.4428C51.2519 22.5441 49.1062 19.6372 47.8643 18.3207C46.5284 16.9051 44.8111 15.1955 42.0708 15.1955C39.8529 15.1955 37.9691 16.7483 36.3928 19.1232L31.6116 16.2902Z" fill="url(#paint0_linear_2551_231)"/>
              <path d="M42.0709 15.1955C39.8528 15.1955 37.9693 16.7483 36.393 19.1232C34.1641 22.4792 32.7993 27.478 32.7993 32.2783C32.7993 34.2583 33.235 35.7786 33.8044 36.6982L29.0176 39.8433C27.7884 37.8037 27.1847 35.1275 27.1847 32.0782C27.1847 26.5329 28.7103 20.7535 31.6117 16.2902C34.1858 12.3319 37.9005 9.56378 42.1613 9.56378L42.0709 15.1955Z" fill="url(#paint1_linear_2551_231)"/>
              <path d="M90.0808 10.653H96.5757L107.619 30.5831L118.664 10.653H125.017V43.401H119.719V18.3025L110.034 35.6829H105.063L95.3791 18.3025V43.401H90.0808V10.653ZM141.619 22.8632C137.819 22.8632 135.531 25.7161 134.983 29.2487H147.879C147.613 25.6096 145.509 22.8632 141.619 22.8632ZM129.73 31.2611C129.73 23.8279 134.546 18.4179 141.711 18.4179C148.76 18.4179 152.968 23.7594 152.968 31.6579V33.1096H134.983C135.621 36.9524 138.177 39.5419 142.299 39.5419C145.587 39.5419 147.642 38.5412 149.591 36.7108L152.406 40.1496C149.754 42.5824 146.381 43.9853 142.111 43.9853C134.354 43.9853 129.73 38.3428 129.73 31.2611ZM159.392 23.3321H154.515V19.0041H159.392V11.8467H164.504V19.0041H171.914V23.3321H164.504V34.3016C164.504 38.0471 165.705 39.378 168.655 39.378C170.001 39.378 170.773 39.2624 171.914 39.073V43.3542C170.493 43.7545 169.137 43.9385 167.67 43.9385C162.151 43.9385 159.392 40.9306 159.392 34.9093V23.3321ZM193.44 27.5409C192.413 24.955 190.123 23.0507 186.758 23.0507C182.382 23.0507 179.582 26.147 179.582 31.1908C179.582 36.1085 182.16 39.3545 186.548 39.3545C189.996 39.3545 192.458 37.3529 193.44 34.8623V27.5409ZM198.552 43.401H193.534V39.9855C192.13 41.9964 189.578 43.9853 185.444 43.9853C178.796 43.9853 174.352 38.4329 174.352 31.1908C174.352 23.8803 178.902 18.4179 185.727 18.4179C189.1 18.4179 191.747 19.7632 193.534 22.1382V19.0041H198.552V43.401Z" fill={props.textColor}/>
          </g>
          <defs>
              <linearGradient id="paint0_linear_2551_231" x1="38.2115" y1="30.6625" x2="74.0028" y2="32.4746" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0064E1"/>
                  <stop offset="0.4" stopColor="#0064E1"/>
                  <stop offset="0.83" stopColor="#0073EE"/>
                  <stop offset="1" stopColor="#0082FB"/>
              </linearGradient>
              <linearGradient id="paint1_linear_2551_231" x1="35.3193" y1="34.6299" x2="35.3193" y2="21.4658" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0082FB"/>
                  <stop offset="1" stopColor="#0064E0"/>
              </linearGradient>
              <clipPath id="clip0_2551_231">
                  <rect width="172.5" height="35.5402" fill="white" transform="translate(26.75 9.22992)"/>
              </clipPath>
          </defs>
      </svg>
  )
}

export default Meta
import s from "./faqBlock.module.scss"
import {useState} from "react";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandFAQ from "../../../static/images/reffilPageIcons/ExpandFAQ";
import {faqInfo} from "../constRefill/const";



const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    width: "100%",
    maxWidth: "548px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
    />
))(({ theme }) => ({
    padding: "0 0 16px 0",
    // maxHeight: "48px",
    gap: "16px",
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },

}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: "16px 0 16px 48px",
}));

const FAQBlock = () => {

    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    return (
        <section className={s.faqBlock}>
            <div className={s.wrapperBlock}>

                <h2 className={s.titleHero}>Часто задаваемые вопросы</h2>

                    <div className={s.faqContainer}>

                        {
                            faqInfo.map( (faqElem, index) => {
                                const {title, description} = faqElem
                                return(
                                    <Accordion
                                        key={index}
                                        expanded={expanded === index}
                                        onChange={handleChange(index)}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel1d-header"
                                            expandIcon={ <ExpandFAQ/> }
                                        >
                                           <span className={s.titleAccordion}>
                                               {
                                                   title
                                               }
                                           </span>
                                        </AccordionSummary>
                                        <AccordionDetails >
                                            <span>
                                               {
                                                   description
                                               }
                                           </span>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }

                    </div>

            </div>
        </section>
    )
}

export default FAQBlock
import {useState} from "react";
import {checkValidation} from "../../Pages/RefillPage/constRefill/validationSheme";
const useValidation = (extraShemValidation= {}) => {
    const [errorObj, setErrorObj] = useState({})

    const validate = (formData) => {
        const resultValidationObj = checkValidation(formData, extraShemValidation)
        if (resultValidationObj.isError){
            const tempErrorOnj = {}
            for (const key in resultValidationObj) {
                if (resultValidationObj[key].length > 0){
                    tempErrorOnj[key] = resultValidationObj[key]
                }
            }
            setErrorObj({...errorObj, ...tempErrorOnj})
            return true
        }else {
            setErrorObj({...errorObj, ...resultValidationObj})
            return false
        }
    }
    return {errorObj, setErrorObj, validate}
}

export default  useValidation
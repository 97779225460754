import {createContext, useEffect, useState} from 'react';
import {generalFormDataStepTwo} from "../Pages/RefillPage/constRefill/stepTwoModalConst";
import {generalFormDataStepOne, getInitialFormDataStepOne} from "../Pages/RefillPage/constRefill/stepOneModalConst";

const RefillModalContext = createContext({});

export const RefillModalProvider = ({children}) => {
    const [openModal, setOpenModal] = useState(false)
    // mode: step1 | step2 | step3 | loading | fullfiled | reject | refill
    const [mode, setMode] = useState("step1")
    const [typeSystem, setTypeSystem] = useState(undefined);
    const [typeOrganization, setTypeOrganization] = useState(undefined)
    const [stepOneFormData, setStepOneFormData] = useState(getInitialFormDataStepOne())
    const [stepTwoFormData, setStepTwoFormData] = useState(generalFormDataStepTwo)
    const [industriesData, setIndustriesData] = useState({})
    const [subIndustryData, setSubIndustryData] = useState([])
    const [rejectMessageList, setRejectMessageList] = useState([])

    return (
        <RefillModalContext.Provider value={{
            openModal,
            setOpenModal,
            mode,
            setMode,
            typeSystem,
            setTypeSystem,
            typeOrganization,
            setTypeOrganization,
            stepTwoFormData,
            setStepTwoFormData,
            stepOneFormData,
            setStepOneFormData,
            subIndustryData,
            setSubIndustryData,
            industriesData,
            setIndustriesData,
            rejectMessageList,
            setRejectMessageList
        }}>
            {children}
        </RefillModalContext.Provider>
    );
};
export default RefillModalContext;

const TikTok = (props) => {
  return(
      <svg width="232" height="57" viewBox="0 0 232 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2418_1013)">
              <path d="M57.2179 20.711C60.7982 23.269 65.1841 24.774 69.9211 24.774V15.6633C69.0246 15.6634 68.1304 15.57 67.2534 15.3843V22.5558C62.5167 22.5558 58.1314 21.0507 54.5502 18.4929V37.0854C54.5502 46.3863 47.0065 53.9256 37.7014 53.9256C34.2295 53.9256 31.0025 52.8765 28.3218 51.0772C31.3813 54.204 35.6482 56.1437 40.3686 56.1437C49.6743 56.1437 57.2183 48.6043 57.2183 39.3031V20.711H57.2179ZM60.5089 11.5194C58.6792 9.52147 57.4779 6.93954 57.2179 4.08511V2.91327H54.6899C55.3262 6.54125 57.4967 9.64078 60.5089 11.5194ZM34.2072 43.94C33.185 42.6003 32.6325 40.9615 32.635 39.2764C32.635 35.0226 36.0855 31.5735 40.3424 31.5735C41.1357 31.5733 41.9243 31.6947 42.6803 31.9345V22.6201C41.7968 22.499 40.9051 22.4477 40.0137 22.4665V29.7164C39.2571 29.4766 38.4681 29.3548 37.6746 29.3556C33.4177 29.3556 29.9675 32.8043 29.9675 37.0587C29.9675 40.0669 31.6921 42.6713 34.2072 43.94Z" fill="#FF004F"/>
              <path d="M54.5502 18.4928C58.1313 21.0505 62.5167 22.5556 67.2533 22.5556V15.3841C64.6094 14.8212 62.2688 13.4402 60.5089 11.5194C57.4965 9.64059 55.3262 6.54106 54.6899 2.91327H48.0493V39.3027C48.0343 43.545 44.5897 46.98 40.342 46.98C37.8388 46.98 35.615 45.7874 34.2066 43.94C31.6917 42.6713 29.9671 40.0667 29.9671 37.0589C29.9671 32.8049 33.4173 29.3558 37.6742 29.3558C38.4898 29.3558 39.2759 29.4827 40.0133 29.7166V22.4667C30.8717 22.6555 23.5197 30.1211 23.5197 39.3029C23.5197 43.8863 25.3505 48.0414 28.3219 51.0774C31.0026 52.8765 34.2297 53.9258 37.7016 53.9258C47.0069 53.9258 54.5504 46.3861 54.5504 37.0854L54.5502 18.4928Z" fill={props.textColor}/>
              <path d="M67.2535 15.3841V13.445C64.8693 13.4487 62.5319 12.7813 60.509 11.5192C62.2997 13.4787 64.6577 14.8298 67.2535 15.3841ZM54.69 2.91333C54.6293 2.56661 54.5827 2.21761 54.5503 1.86727V0.695435H45.3815V37.0852C45.3669 41.3269 41.9225 44.762 37.6744 44.762C36.4272 44.762 35.2496 44.466 34.2068 43.9403C35.6152 45.7875 37.839 46.9798 40.3421 46.9798C44.5895 46.9798 48.0346 43.5452 48.0494 39.3029V2.91333H54.69ZM40.0138 22.4668V20.4024C39.2477 20.2978 38.4753 20.2452 37.7019 20.2456C28.3959 20.2456 20.8522 27.7851 20.8522 37.0852C20.8522 42.9159 23.817 48.0544 28.3223 51.0771C25.3508 48.0413 23.52 43.886 23.52 39.3028C23.52 30.1212 30.8719 22.6555 40.0138 22.4668Z" fill="#00F2EA"/>
              <path d="M173.493 46.3014C180.151 46.3014 185.549 40.9473 185.549 34.3433C185.549 27.7394 180.151 22.3843 173.493 22.3843H171.677C178.335 22.3843 183.733 27.7392 183.733 34.3433C183.733 40.9473 178.335 46.3014 171.677 46.3014H173.493Z" fill="#FF004F"/>
              <path d="M171.512 22.3843H169.696C163.038 22.3843 157.639 27.7392 157.639 34.3433C157.639 40.9473 163.038 46.3014 169.696 46.3014H171.512C164.853 46.3014 159.455 40.9473 159.455 34.3433C159.455 27.7394 164.853 22.3843 171.512 22.3843Z" fill="#00F2EA"/>
              <path d="M119.986 16.9794V30.5743L126.921 23.6945H135.343L126.59 32.2137L136.335 46.3012H128.904L122.133 36.6356L119.986 38.603V46.1376H113.05V16.9794H119.986ZM194.798 16.9794V30.5743L201.734 23.6945H210.156L201.403 32.2137L211.148 46.3012H203.716L196.944 36.6356L194.798 38.603V46.1376H187.86V16.9794H194.798ZM171.676 22.3843C178.334 22.3843 183.733 27.7394 183.733 34.3433C183.733 40.9473 178.334 46.3014 171.676 46.3014H171.512C164.853 46.3014 159.456 40.9473 159.456 34.3433C159.456 27.7392 164.853 22.3843 171.512 22.3843H171.676ZM101.82 16.9794L99.8384 23.2045H94.0588V46.1378H86.9558V23.0409H79.8557V16.9794H101.82ZM159.952 16.9794L157.97 23.2045H152.191V46.1378H145.088V23.0409H137.988V16.9794H159.952ZM110.077 26.317V46.1378H103.143V26.317H110.077ZM171.595 28.5288C168.357 28.5288 165.731 31.1309 165.731 34.3433C165.731 37.5556 168.357 40.1587 171.595 40.1593C174.831 40.1593 177.457 37.5556 177.457 34.3433C177.457 31.1309 174.831 28.5288 171.595 28.5288ZM106.608 16.9794C108.525 16.9794 110.077 18.5198 110.077 20.4192C110.077 22.3187 108.526 23.8591 106.608 23.8591C104.693 23.8582 103.143 22.3187 103.143 20.4192C103.143 18.5198 104.693 16.9794 106.608 16.9794Z" fill={`${props.textColor}`}/>
          </g>
          <defs>
              <clipPath id="clip0_2418_1013">
                  <rect width="231.034" height="55.4483" fill="white" transform="translate(0.482788 0.695435)"/>
              </clipPath>
          </defs>
      </svg>

  )
}

export default TikTok
import CustomInput from "../../CustomInput/CustomInput";
import React, {useContext, useState} from "react";
import  s from "./index.module.scss"
import Button from "@mui/material/Button";
import {selectOptionSystem} from "../../constRefill/stepOneModalConst";
import CustomSelect from "../../CustomSelect/CustomSelect";
import {calculate} from "../../constRefill/calc";
import {get_redirect_url} from "../../../../api/RefillApi";
import RefillModalContext from "../../../../context/RefillModalContext";
import {getListError} from "../../constRefill/parserErrorData";
import useValidation from "../../../../Components/hooks/useValidation";
import {formatNumber} from "../../constRefill/const";

const Refill = () => {
    const {setMode, setRejectMessageList} = useContext(RefillModalContext)

    const extraShemValidation = {
        bill_sum : {
            regex: "^(?:(?:1[5-9] \\d{3}([,\\.]\\d{1,2})?))|(?:(?:[2-9]\\d{1} \\d{3}([,\\.]\\d{1,2})?)|(:?[1-4]\\d{2} \\d{3}([,\\.]\\d{1,2})?)|500 000|500 000.0|500 000.00)$",
            errorMessage: "Сумма оплаты не должна быть меньше 15000 и не привышать 500000"
        }
    }

    const initialCurrentSumData = {
        bill_sum: "",
        sum_refill: "",
        sum_with_NDS: "",
        sum_cashback_commission: "",
        cashback_commission: {
            "Google Ads": "Комиcсия 5%",
            "Yandex Direct": "Кэшбек 5%",
            "TikTok": "Комиcсия 5%",
            "Facebook": "Комиcсия 5%",
        },
    }

    const [currentSumData, setCurrentSumData] =useState(initialCurrentSumData)

    const [formData, setFormData] = useState({login: "", bill_sum: "", system_name: null})
    const { errorObj, validate, setErrorObj } = useValidation(formData.system_name === "Yandex Direct" && extraShemValidation)

    const handleOnClick = async () => {
        const isError = validate(formData)
        if(!isError) {
            setErrorObj({})
            try {
                const resGetRedirectUrl =  await get_redirect_url({...formData, bill_sum: +formData.bill_sum.replace(" ", "")})
                setMode("fullfiled")
                if(resGetRedirectUrl.data !== null){
                    console.log("freedom Url",resGetRedirectUrl.data.url)
                    window.location.href = resGetRedirectUrl.data.url
                }

            }catch (error){
                setRejectMessageList(getListError(error.response.data))
                setMode("reject")
            }
        }
    }

    const  handleChange = async (e) => {
        if(e.target.name === "system_name"){
            setCurrentSumData({...initialCurrentSumData})
            setErrorObj({})
        }
        setFormData({...formData, [e.target.name]: e.target.value})
    }


    const handleCalculate = (e) => {
        const adSystem = formData.system_name
        let temResultCalculation
        switch (adSystem) {
            case "Yandex Direct" :{
                const data = {
                    type_calculation:"cashback",
                    type_input: e.target.name,
                    main_input: "bill_sum",
                    x: e.target.value.replaceAll(/[^\d.,]/g, ''),
                    y: 1.05
                }
                temResultCalculation = calculate(data)
                break;
            }
            default: {
                const data = {
                    type_calculation:"commission",
                    type_input: e.target.name,
                    main_input: "bill_sum",
                    x: e.target.value.replaceAll(/[^\d.,]/g, ''),
                    y: 1.05
                }
                temResultCalculation = calculate(data)
                break
            }
        }
        let resultCalculation = {}
        for (const key in temResultCalculation) {
            resultCalculation = {...resultCalculation, [key]: formatNumber(temResultCalculation[key])}
        }
        setCurrentSumData({...currentSumData, [e.target.name]: formatNumber(e.target.value), ...resultCalculation})
        setFormData({...formData, bill_sum: currentSumData.bill_sum})
    }

  return(
      <div className={s.refillBlock}>
          <h2 className={s.title}>Пополнение аккаунта</h2>
          <div className={s.inputContainer}>

              <CustomSelect
                  label="Выберите систему"
                  name="system_name"
                  selectValue={formData.system_name}
                  selectData={selectOptionSystem}
                  placeholder="Выберите систему"
                  selectOnChange={handleChange}
                  onBlur={validate}
                  helperText={errorObj.system_name}
                  error={!!errorObj.system_name}
              />

              <CustomInput
                  width={320}
                  type="text"
                  label="id аккаунта"
                  name="login"
                  value={formData.login}
                  onChange={handleChange}
                  onBlur={validate}
                  helperText={errorObj.login}
                  error={!!errorObj.login}
              />

              <CustomInput
                  width={320}
                  type="text"
                  label="Сумма к оплате"
                  name="bill_sum"
                  disabled={formData.system_name === null}
                  value={currentSumData.bill_sum}
                  onChange={handleCalculate}
                  onBlur={validate}
                  helperText={errorObj.bill_sum}
                  error={!!errorObj.bill_sum}
              />

              <CustomInput
                  type="text"
                  label="НДС"
                  disabled
                  value={currentSumData.sum_with_NDS || ""}
                  onBlur={()=>{}}
              />

              <CustomInput
                  type="text"
                  label={currentSumData.cashback_commission[formData.system_name] || "Комиссия / Кэшбек"}
                  disabled
                  value={currentSumData.sum_cashback_commission || ""}
                  onBlur={()=>{}}
              />

              <CustomInput
                  width={320}
                  type="text"
                  label="Сумма пополнения"
                  name="sum_refill"
                  disabled={formData.system_name === null}
                  value={currentSumData.sum_refill || ""}
                  onChange={handleCalculate}
                  onBlur={()=>{}}
              />

          </div>

          {
              formData.system_name !== "Yandex Direct"
                  ? <span className={s.calcHelperText}>Зачисление на аккаунт будет в USD, курс конвертациификсируется момент поступления денег по банку БЦК.</span>
                  : null
          }

          <Button
              sx={{
                  "&:hover": {
                      background: "#fdba2f"
                  }
              }}
              onClick={handleOnClick}
              className={s.nextBtn}>
              Пополнить
          </Button>
      </div>
  )
}

export default Refill
import s from "./contactBlock.module.scss"
import EmailImg from "../../../static/images/reffilPageIcons/Email.svg"
import PhoneImg from "../../../static/images/reffilPageIcons/Phone.svg"
import TelegramImg from "../../../static/images/reffilPageIcons/Telegram.svg"
import WhatsAppImg from "../../../static/images/reffilPageIcons/WhatsApp.svg"
const ContactBlock = () => {

    const contactInfo = [
        {title: "Email", img: EmailImg , description: "tds@tds.media", type: "mailto: tds@tds.media"},
        {title: "Телефон", img: PhoneImg , description: "+7 (700) 122-22-24", type: "tel: +77001222224"},
        {title: "Telegram", img: TelegramImg , description: "+7 (700) 122-22-24", type: "tel: +77001222224"},
        {title: "WhatsApp", img: WhatsAppImg , description: "+7 (700) 122-22-24", type: "tel: +77001222224"},
    ]

  return(
      <div className={s.contactBlock}>
          <div className={s.wrapperBlock}>
              <h2 className={s.titleHero}>Контакты</h2>
              <span className={s.helperText}>Появились вопросы?</span>
              <div className={s.contactsContainer}>
                  {
                      contactInfo.map((contactElem, index) => {
                          const {title, type, description, img} = contactElem
                          return(
                              <div key={index} className={s.contactContainer}>
                                  <img src={img} alt={title}/>
                                  <div className={s.descriptionContainer}>
                                      <span className={s.descriptionTitle}>{title}</span>
                                      <a className={s.descriptionLink} href={type}>{description}</a>
                                  </div>
                              </div>
                          )
                      })
                  }
              </div>
          </div>
      </div>
  )
}

export default ContactBlock
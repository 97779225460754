import {Modal} from '@mui/material';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomModal(props) {
    const handleClose = () => {
        if (!props.handleClose) {
            props.setOpen(false);
        } else {
            props.handleClose();
        }
    };

    return (
        <Modal open={props.open} disableAutoFocus={true}>
            <Box
                sx={{
                    position: 'absolute',
                    top: `${props.top ? props.top + "%" : '50%'}`,
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    p: 3,
                    background: '#FFF',
                    maxWidth: '100vw',
                    overflow: {
                        xs: "scroll"
                    },
                    maxHeight:{
                        xs: '700px',
                    },
                    width: {
                        xs: '100vw',
                        md: `${props.width ? props.width + "px" : "inherit"}`
                    },
                }}
                className={'main-container'}
            >
                <CloseIcon
                    sx={{
                        position: 'absolute',
                        right: 24,
                        top: 24,
                        color: '#000',
                        width: 24,
                        height: 24,
                        zIndex: 10,
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                />
                {props.children}
            </Box>
        </Modal>
    );
}
